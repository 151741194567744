import { Reducer } from 'redux'
import { Country } from '../domain'
import { PayloadAction } from './payload-action'
import { SettingsState } from './settings'

type LoginAction = PayloadAction<'LOG_IN', { user: { loggedInUsername: string; dataOwnerUsername: string } }>
type UpdateLoggedInUserAction = PayloadAction<
  'UPDATE_LOGGED_IN_USER',
  { employee: { firstName: string; lastName: string; groups: string; startDay: string } }
>
export type UpdateDataOwnerAction = PayloadAction<
  'UPDATE_DATA_OWNER_USER',
  {
    employee: {
      firstName: string
      lastName: string
      groups: string
      startDay: string
      totalHoursDifference: number
      startEndShown: SettingsState['startEndShown']
      monthLockFeatureEnabled: SettingsState['monthLockFeatureEnabled']
    }
  }
>
type UpdateManagedCountriesAction = PayloadAction<
  'UPDATE_MANAGED_COUNTRIES',
  { managedCountries: LoggedInUser['managedCountries'] }
>
export type UserAction = LoginAction | UpdateLoggedInUserAction | UpdateDataOwnerAction | UpdateManagedCountriesAction

export type LoggedInUser = {
  startDate: string
  fullName: string
  isExternal: boolean
  isAdmin: boolean
  isCountryManager: boolean
  isCountryStatsUser: boolean
  managedCountries: Country[]
}
export interface UserState {
  loggedInUsername: string
  loggedInUser: LoggedInUser
  dataOwnerUsername?: string
  dataOwnerUser: {
    startDate: string
    fullName: string
    isExternal: boolean
  }
}

const DEFAULT_USER_STATE: UserState = {
  loggedInUsername: '',
  loggedInUser: {
    startDate: '',
    fullName: '',
    isExternal: false,
    isAdmin: false,
    isCountryManager: false,
    isCountryStatsUser: false,
    managedCountries: [],
  },
  dataOwnerUsername: '',
  dataOwnerUser: {
    startDate: '',
    fullName: '',
    isExternal: false,
  },
}

const HIDDEN_COUNTRIES = ['AE', 'SE']

export const user: Reducer<UserState, UserAction> = (state = DEFAULT_USER_STATE, action) => {
  switch (action.type) {
    case 'LOG_IN': {
      const { loggedInUsername, dataOwnerUsername } = action.user
      return { ...state, loggedInUsername, dataOwnerUsername }
    }
    case 'UPDATE_LOGGED_IN_USER': {
      const { firstName, lastName, groups, startDay } = action.employee
      const groupsArray = groups.split(',')
      return {
        ...state,
        loggedInUser: {
          ...state.loggedInUser,
          fullName: `${firstName} ${lastName}`,
          startDate: startDay,
          isExternal: groupsArray.includes('ext'),
          isAdmin: groupsArray.includes('admin'),
          isCountryManager: groupsArray.includes('countryManager'),
          isCountryStatsUser: groupsArray.includes('countryStatsUser'),
        },
      }
    }
    case 'UPDATE_DATA_OWNER_USER': {
      const { firstName, lastName, groups, startDay } = action.employee
      const groupsArray = groups.split(',')
      return {
        ...state,
        dataOwnerUser: {
          fullName: `${firstName} ${lastName}`,
          startDate: startDay,
          isExternal: groupsArray.includes('ext'),
        },
      }
    }
    case 'UPDATE_MANAGED_COUNTRIES': {
      const { managedCountries } = action

      const filteredCountries = managedCountries.filter((country) => !HIDDEN_COUNTRIES.includes(country.id))
      filteredCountries.sort((a, b) => a.name.localeCompare(b.name))

      return {
        ...state,
        loggedInUser: {
          ...state.loggedInUser,
          managedCountries: filteredCountries,
        },
      }
    }
    default:
      return state
  }
}
